export default {
    data() {
        return {
            imageName: '',
            imageSrc: '',
            croppedImageSrc: '',
            uploadedImage: ''
        };
    },

    methods: {
        pickImage() {
            this.$refs.image.click();
        },

        setImage(event) {
            const file = event.target.files[0];

            this.imageName = file.name;

            const reader = new FileReader();

            reader.onload = event => {
                this.imageSrc = event.target.result;

                if (this.$refs.cropper) {
                    this.$refs.cropper.replace(event.target.result);
                }
            };

            reader.readAsDataURL(file);
        },

        cropImage() {
            this.croppedImageSrc = this.$refs.cropper
                .getCroppedCanvas()
                .toDataURL();

            this.$refs.cropper.replace(this.croppedImageSrc);
        },

        onImageUpload() {
            try {
                this.$refs.cropper.getCroppedCanvas().toBlob(async blob => {
                    blob.name = this.imageName;

                    const uploadedImage = await this.uploadImage({
                        image: blob
                    });

                    this.uploadedImage = uploadedImage.filename;

                    this.$toasted.success('Image has been uploaded!');
                });
            } catch (error) {
                console.error(error);

                this.$toasted.error('Something went wrong!');
            }
        },

        resetCrop() {
            this.$refs.cropper.reset();
            this.$refs.cropper.replace(this.imageSrc);
        },

        deleteImage() {
            this.uploadedImage = '';
            this.imageName = '';
            this.imageSrc = '';
            this.croppedImageSrc = '';

            const input = this.$refs.image;
            input.type = 'text';
            input.type = 'file';
        }
    }
};
