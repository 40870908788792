export default {
    data() {
        return {
            drag: false
        };
    },

    methods: {
        async changeOrder(items, page, itemsPerPage) {
            this.drag = false;

            window.addEventListener(
                'mousemove',
                () => (this.disableHoverEffect = false),
                { once: true }
            );

            const elements = items.map((item, index) => ({
                id: item.id || item,
                order: index + (page - 1) * itemsPerPage
            }));

            try {
                await this.updateOrder(elements);

                await this.getTableItems();

                this.$toasted.success('Position has been updated!');
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        }
    }
};
